export default Object.freeze({
    // URL: "https://test.ipphonecamera.deskshare.com/wcapi",
    URL: "https://ipphonecamera.deskshare.com/wcapi",
    RECORD_VIDEO_LINK: "https://ipphonecamera.deskshare.com/#/record_video",
    COMMANDS: "https://ipphonecamera.deskshare.com/wcapi/command",
    TOKEN: "token",
    LANGUAGE_CODE: "language_code",
    FLASH: "flashlight",
    AUTO_FOCUS: "autofocus",
    ROTATE: "rotatecamera",
    SWITCH_CAMERA: "switchcamera",
    SET_ZOOM: "setzoom",
    GET_ZOOM: "getzoom",
    SET_WHITE_BALANCE: "setblackwhitebalance",
    GET_WHITE_BALANCE: "getblackwhitebalance",
    CAMERA_SWITCH_ON_OFF: "cameraViewOnOff",
    LOGOUT:"stop"
})